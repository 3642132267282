import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import VueMeta from 'vue-meta'
import 'bootstrap/dist/js/bootstrap'
import './assets/scss/styles.scss'
import VueLazyload from 'vue-lazyload'

createApp(App)
.use(VueLazyload, {
    preLoad: 1.3,
    error: 'https://s3-propozzito.s3.amazonaws.com/membros/storage/app/public/users/default.png',
    loading: 'loading.gif',
    attempt: 1
})
.use(router)
.mount('#app')

<template>
  <div class="bg-primary p-4 pb-5">
    <div class="container d-flex justify-content-center align-items-center">
      <img src="../assets/logo-ieadern-60-60.png" width="90" height="90">
      <h1 class="text-center text-white h2 fw-bold px-2 m-0">Rodízio de Pastores {{ this.$route.params.ano }}</h1>
    </div>
  </div>

  <div class="container mt-n5">
    <div class="form-floating mb-3">
      <input type="email" class="form-control" id="filtro" placeholder="Filtre por setor, congregação ou dirigente"
        autofocus @keyup="filtrar()" v-model="search">
      <label for="filtro" class="fw-bold text-muted">Filtre por setor, congregação ou dirigente</label>
    </div>
  </div>

  <div class="container">

    <div class="list-group mb-3" v-for="(rodizio, index) in rodiziosExibir" :key="index">

      <div class="list-group-item list-group-item-action active" :style="rodizio.area ? {'display': 'flex', 'justify-content': 'space-between'} : ''">
        <h2 class="fw-bold h6 m-0" v-if="rodizio.area">Área {{ rodizio.area }}</h2>
        <h2 class="fw-bold h6 m-0">Setor {{ rodizio.setor }}</h2>
      </div>

      <div class="list-group-item list-group-item-action d-flex text-left"
        v-for="(congregacao, i) in rodizio.congregacoes" :key="i">
        <img v-lazy="congregacao.foto_pastor" width="90" height="90" class="rounded-circle">
        <div class="px-2 d-flex flex-column justify-content-center">
          <h3 class="h6 fw-bold text-muted m-0">{{ congregacao.nome }}</h3>
          <p class="m-0 text-muted">{{ congregacao.pastor }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="bnt-years">
    <a type="button" class="btn btn-outline-primary item left" :href="'/' + (parseInt(this.$route.params.ano) - 1)"
      :class="{ disabled: parseInt(this.$route.params.ano) <= 2019 }">{{ parseInt(this.$route.params.ano) - 1 }}</a>
    <a type="button" class="btn btn-outline-primary item right" :href="'/' + (parseInt(this.$route.params.ano) + 1)"
      :class="{ disabled: parseInt(this.$route.params.ano) >= new Date().getFullYear() }">{{
        parseInt(this.$route.params.ano) + 1 }}</a>
  </div>
  <a href="https://propozzito.com?utm_source=IEADERN&utm_medium=Logomarca&utm_campaign=Rod%C3%ADzio+2023">
    <img src="https://propozzito.com/assets/images/logo-propozzito-redesign-horizontal-219x51.png"
      class="p-2 d-block mx-auto">
  </a>
</template>

<script>
import data2024 from '../data/2024.json'
var url_atual = window.location.href;
let parts = url_atual.split('/');
let lastPart = parts.pop() || parts.pop();
const dataAtual = new Date();
const anoAtual = dataAtual.getFullYear();

if (lastPart < 2019) {
  history.pushState({}, null, 2019);
} else if (lastPart > anoAtual) {
  history.pushState({}, null, anoAtual);
}

import { rodizioService } from "../api/rodizio";

// @ is an alias to /src
export default {
  name: 'HomeView',
  // metaInfo: {
  //   title: 'My Vue.js App'
  // },
  data() {
    return {
      rodizios: [],
      // rodiziosExibir: [],
      search: ''
    }
  },
  methods: {
    carregarDados() {
      if (this.$route.params.ano == 2024) {
        // Open file
        this.carregarDadosPeloJson();
      } else {
        this.carregarDadosPelaApi();
      }
    },
    carregarDadosPeloJson() {
      console.log(data2024);
      const reorganizado = data2024.reduce((acumulador, atual) => {
        const encontrado = acumulador.find(item => item.setor === atual.setor);
        if (encontrado) {
          encontrado.congregacoes.push({
            foto_pastor: 'https://s3-propozzito.s3.amazonaws.com/membros/storage/app/public/' + atual.foto_dirigente,
            nome: atual.congregacao,
            pastor: atual.dirigente
          });
        } else {
          acumulador.push({
            setor: atual.setor,
            area: atual.area,
            congregacoes: [
              {
                foto_pastor: 'https://s3-propozzito.s3.amazonaws.com/membros/storage/app/public/' + atual.foto_dirigente,
                nome: atual.congregacao,
                pastor: atual.dirigente
              }
            ]
          });
        }
        return acumulador;
      }, []);

      this.rodizios = reorganizado;
    },
    carregarDadosPelaApi() {
      rodizioService.pegarRodizioPorAno(this.$route.params.ano)
        .then(success => {

          for (let obj in success['data']['setores']) {
            this.rodizios.push({
              setor: obj,
              congregacoes: success['data']['setores'][obj]['congregacoes']
            });
          }

          // this.rodiziosExibir = this.rodizios;
        })
    },
    filtrar() {

    },
    replaceByDefault(e) {
      e.target.src = 'https://s3-propozzito.s3.amazonaws.com/membros/storage/app/public/users/default.png';
    }
  },
  computed: {
    rodiziosExibir() {
      let rodizios = [];

      this.rodizios.forEach((rodizio) => {
        rodizios.push({
          setor: rodizio.setor,
          area: rodizio.area ?? null,
          congregacoes: rodizio.congregacoes.filter(congregacao => {
            return (
              congregacao.nome.toLowerCase().includes(this.search.toLowerCase()) ||
              congregacao.pastor.toLowerCase().includes(this.search.toLowerCase()) ||
              rodizio.setor.toString().includes(this.search)
            );
          })
        });
      });


      rodizios = rodizios.filter(function (rodizio) {
        return rodizio.congregacoes.length > 0;
      });

      return rodizios;
    }
  },
  created: function () {
    this.carregarDados()
  }
}
</script>

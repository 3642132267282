import axios from 'axios';

const headers =  {
    'Accept': 'application/json',
    'X-Request-With': 'XMLHttpRequest',
    'Content-Type': 'application/x-www-form-urlencoded'
};

const client = axios.create({
    baseURL: `https://secretaria.ieadern.org.br/api`,
    headers
})

// client.defaults.withCredentials = true;

// client.defaults.headers.common['Accept'] = 'application/json';
// client.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// client.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";

export {
    client
}

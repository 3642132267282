import {client} from "../client";

const rodizioService = {
    pegarRodizioPorAno(ano) {
        return client.get(`rodizios/pegarPorAno/${ano}`);
    }
}

export {
    rodizioService
}
